<template>
  <div>
    <b-row>
      <b-col>
        <datatable
          ref="concessoesDatatable"
          :customFields="customFields"
          :customActions="customActions"
          :showExportToExcell="true"
          :exportToExcellAction="downloadRemittancesXlsx"
        >
          <template #table-header-actions>
            <div class="d-flex justify-content-start align-items-center">
              <v-select
                v-model="selectedAssignor"
                :options="assignors"
                class="assignor-selector d-flex flex-column mr-2"
                label="nome"
                placeholder="Cedente"
              >
              </v-select>
              <v-select
                v-model="selectedStatus"
                :options="statuses"
                class="assignor-selector d-flex flex-column mr-2"
                placeholder="Status"
              >
              </v-select>
              <flat-pickr
                v-model="selectedDate"
                class="assignor-selector form-control bg-white"
                :config="config"
                placeholder="Busca por data"
              />
            </div>
          </template>
        </datatable>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BModal,
  BTable,
  BBadge,
  BButton,
  BTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import Datatable from '@/views/common/crud/components/Datatable'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import {
  remessaStatusBadge,
  moneyFormatDatatable,
  brazilianFormattedDate,
} from '@core/comp-functions/data_visualization/datatable'
import downloadFile from '@/utils/download-file'

export default {
  name: 'Concessoes',
  components: {
    BCol,
    BRow,
    BCard,
    BTable,
    BModal,
    BBadge,
    BButton,
    vSelect,
    BTooltip,
    flatPickr,
    Datatable,
    BFormCheckbox,
  },
  data() {
    return {
      selectedDate: '',
      selectedStatus: null,
      selectedAssignor: null,
      assignors: [],
      statuses: [
        { value: 'importado', label: 'Importado' },
        { value: 'disponivel', label: 'Disponivel' },
        { value: 'aguardando_assinatura', label: 'Aguardando assinatura' },
        { value: 'assinada', label: 'Assinada' },
        { value: 'concluido', label: 'Concluída' },
        { value: 'erro', label: 'Erro' },
        { value: 'cancelada', label: 'Cancelada' },
      ],
      config: {
        mode: 'range',
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      customFields: [
        {
          label: 'Número',
          key: 'numero',
          sortable: true,
        },
        {
          label: 'Cedente',
          key: 'cedente.nome',
          sortable: true,
        },
        {
          label: 'Data de envio',
          key: 'created_at',
          sortable: true,
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Qtd. de títulos',
          key: 'quantidade_titulos',
        },
        {
          label: 'Valor de face',
          key: 'total_valor_face',
          sortable: true,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Valor operado',
          key: 'total_valor_operado',
          sortable: true,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Tipo',
          key: 'descricao_tipo',
          sortable: true,
        },
        {
          label: 'Status',
          key: 'status',
          formatter: remessaStatusBadge,
        },
      ],
      customActions: [
        {
          label: 'Visualizar remessa',
          icon: 'icon-search',
          action: 'show',
          name: 'partner.remessas.show',
        },
      ],
    }
  },
  async mounted() {
    await this.getAssignors()
  },
  methods: {
    async getAssignors() {
      const data = await this.$store.dispatch('admin/getAssignors')
      this.assignors = data
    },
    async downloadRemittancesXlsx() {
      try {
        const { data } = await this.$store.dispatch(
          'admin/exportRemittances',
          this.$refs.concessoesDatatable.params,
        )
        downloadFile(data, 'remessas.xlsx', 'text/xlsx')
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Ocorreu um erro ao tentar baixa o arquivo.',
        })
      }
    },
  },
  computed: {
    params() {
      let params = {}

      if (this.selectedAssignor) params.cedente_id = this.selectedAssignor.id
      if (this.selectedStatus) params.status = this.selectedStatus.value
      if (this.selectedDate.includes(' até ')) {
        let [inicio, fim] = this.selectedDate.split(' até ')
        params.data_inicio = inicio
        params.data_fim = fim
      } else {
        params.data_inicio = this.selectedDate
      }

      if (this.datatableFilterValues) params = { ...params, ...this.datatableFilterValues }

      return params
    },
  },
  watch: {
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.$refs.concessoesDatatable.updateFilterValues(newValue)
    },
  },
}
</script>

<style>
.assignor-selector {
  min-width: 240px;
}
</style>
